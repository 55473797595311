(function () {
	'use strict';
	angular.module('eventvods', ['ngRoute', 'ngCookies', 'duScroll', 'ev.directives', 'xeditable', 'angulartics', 'angulartics.google.analytics'])
		.constant('DOMAIN', 'https://eventvods.com')
		.constant('API_BASE_URL', '/api')
		.config(['$compileProvider', function ($compileProvider) {
			$compileProvider.debugInfoEnabled(false);
		}])
		.config(['$cookiesProvider', '$windowProvider', function ($cookiesProvider, $windowProvider) {
			var $window = $windowProvider.$get();
			var now = new $window.Date(),
			// this will set the expiration to 6 months
			exp = new $window.Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
			$cookiesProvider.defaults.expires = exp;
		}])
		.run(function ($rootScope, $anchorScroll, DOMAIN, $location) {
			$rootScope.oldGame = null;
			$rootScope.$on('$locationChangeStart', function () {
				$('body').removeClass('modal-open');
				$('.modal-backdrop').remove();
				$('.modal.show').hide();
			});
			$rootScope.$on('$routeChangeSuccess', function (evt, current, previous) {
				$anchorScroll("top");
				if (current != previous && current != void 0 && current.$$route != void 0) {
					current.$$route.meta.url = DOMAIN + $location.path();
					$rootScope.meta = current.$$route.meta;
					if($rootScope.meta.title !== false) document.title = $rootScope.meta.title;
				} else $rootScope.meta = {
					status: 404
				}
			});
		})
		.run(['$route', '$rootScope', '$location', function ($route, $rootScope, $location) {
			var original = $location.path;
			$location.path = function (path, reload) {
				if (reload === false) {
					var lastRoute = $route.current;
					var un = $rootScope.$on('$locationChangeSuccess', function () {
						$route.current = lastRoute;
						un();
					});
				}
				return original.apply($location, [path]);
			};
		}])
		.run(function(editableOptions, editableThemes) {
			editableOptions.theme = 'default';
			editableThemes['default'].submitTpl = '<button type="submit"><img src="/assets/images/icons/submit.svg" /></button>';
			editableThemes['default'].cancelTpl = '<button type="button" ng-click="$form.$cancel()"><img src="/assets/images/icons/cancel.svg" /></button>';
		  })
		// Offset filter for paging lists
		.filter('offset', function () {
			return function (input, start) {
				start = parseInt(start, 10);
				return input.slice(start);
			};
		})
		// $http interceptor to convert Date strings into js Date objects.
		.config(['$httpProvider', function ($httpProvider) {

			// ISO 8601 Date Pattern: YYYY-mm-ddThh:MM:ss
			var dateMatchPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

			var convertDates = function (obj) {
				for (var key in obj) {
					if (!obj.hasOwnProperty(key)) continue;

					var value = obj[key];
					var typeofValue = typeof (value);

					if (typeofValue === 'object') {
						// If it is an object, check within the object for dates.
						convertDates(value);
					} else if (typeofValue === 'string') {
						if (dateMatchPattern.test(value)) {
							obj[key] = new Date(value);
						}
					}
				}
			}

			$httpProvider.defaults.transformResponse.push(function (data) {
				if (typeof (data) === 'object') {
					convertDates(data);
				}
				return data;
			});
		}])
		.config(['$locationProvider', '$routeProvider', function ($locationProvider, $routeProvider) {
			$routeProvider
				.when('/_=_', {
					redirectTo: '/'
				})
				.when('/', {
					templateUrl: '/assets/views/frontend/featured.html',
					controller: 'FeaturedController',
					controllerAs: 'Events',
					meta: {
						title: 'Eventvods - Esports on Demand',
						description: 'Watch esports matches on demand easily and spoiler-free on Eventvods. Follow your favorite League of Legends, CS:GO, Dota & Overwatch teams.',
						event: false
					}
				})
				.when('/login', {
					templateUrl: '/assets/views/frontend/login.html',
					controller: 'LoginController',
					controllerAs: 'Login',
					meta: {
						title: 'User Login - Eventvods - Esports on Demand',
						description: 'Watch esports matches on demand easily and spoiler-free on Eventvods. Follow your favorite League of Legends, CS:GO, Dota & Overwatch teams.',
						event: false
					}
				})
				.when('/register', {
					templateUrl: '/assets/views/frontend/register.html',
					controller: 'RegisterController',
					controllerAs: 'Register',
					meta: {
						title: 'User Registration - Eventvods - Esports on Demand',
						description: 'Watch esports matches on demand easily and spoiler-free on Eventvods. Follow your favorite League of Legends, CS:GO, Dota & Overwatch teams.',
						event: false
					}
				})
				.when('/featured/:slug', {
					templateUrl: '/assets/views/frontend/featured.html',
					controller: 'FeaturedController',
					controllerAs: 'Events',
					meta: {
						title: false,
						description: 'Watch esports matches on demand easily and spoiler-free on Eventvods. Follow your favorite League of Legends, CS:GO, Dota & Overwatch teams.',
						event: false
					}
				})
				.when('/events/', {
					templateUrl: '/assets/views/frontend/events.html',
					controller: 'EventsListController',
					controllerAs: 'Events',
					meta: {
						title: 'Browse Events - Eventvods',
						description: 'Browse esports vods and highlights for all the latest events, easily and spoiler-free. Rate matches of your favorite teams and share them with friends.',
						event: false
					}
				})
				.when('/events/:slug', {
					templateUrl: '/assets/views/frontend/events.html',
					controller: 'EventsListController',
					controllerAs: 'Events',
					meta: {
						title: false,
						description: 'Browse esports vods and highlights for all the latest events, easily and spoiler-free. Rate matches of your favorite teams and share them with friends.',
						event: false
					}
				})
				.when('/lol/how-to-watch-worlds-2018-spoiler-free', {
					templateUrl: '/assets/views/frontend/worlds-2018.html',
					controller: 'StaticController',
					meta: {
						title: 'How to Watch Worlds 2018 Spoiler-free: a Thorough Guide on Avoiding Esports Spoilers',
						description: 'Find out how to watch Worlds 2018 without being spoiled while you’re at school/work/sleeping. This year LoL world championship matches start at 1 am PDT and 10 am CEST.',
						snapshot: "https://eventvods.com/assets/images/worlds-2018.jpg"
					},
					game: 'lol',
					reloadOnSearch: false
				})
				.when('/lol/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your League of Legends Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/dota/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your Dota2 Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/csgo/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your CS:GO Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/ow/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your Overwatch Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/overwatch/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your Overwatch Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/rocket-league/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your Rocket League Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/valorant/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your VALORANT Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/event/:slug', {
					templateUrl: '/assets/views/frontend/event.html',
					controller: 'EventController',
					controllerAs: 'Event',
					meta: {
						title: false,
						description: 'Watch all your favourite Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					},
					reloadOnSearch: false
				})
				.when('/:gameslug/:eventslug/:teams/facebook/:match', {
					templateUrl: '/assets/views/frontend/facebook.html',
					controller: 'FBPlayerController',
					controllerAs: 'FBPlayer',
					reloadOnSearch: false,
					meta: {
						title: false,
						description: 'Watch all your favourite Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					}
				})
				.when('/:gameslug/:eventslug/:teams/twitch/:match', {
					templateUrl: '/assets/views/frontend/twitch.html',
					controller: 'TWPlayerController',
					controllerAs: 'TWPlayer',
					reloadOnSearch: false,
					meta: {
						title: false,
						description: 'Watch all your favourite Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					}
				})
				.when('/:gameslug/:eventslug/:teams/:match', {
					templateUrl: '/assets/views/frontend/video.html',
					controller: 'PlayerController',
					controllerAs: 'Player',
					reloadOnSearch: false,
					meta: {
						title: false,
						description: 'Watch all your favourite Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: true
					}
				})
				.when('/team/:slug', {
					templateUrl: '/assets/views/frontend/team.html',
					controller: 'TeamController',
					controllerAs: 'Team',
					meta: {
						title: false,
						description: 'Follow your favourite team\'s latest matches across all the popular esports with Eventvods.',
						event: false
					}
				})
				.when('/team/:slug/:game', {
					templateUrl: '/assets/views/frontend/team.html',
					controller: 'TeamController',
					controllerAs: 'Team',
					meta: {
						title: false,
						description: 'Follow your favourite team\'s latest matches across all the popular esports with Eventvods.',
						event: false
					}
				})
				.when('/about/cookies', {
					templateUrl: '/assets/views/frontend/cookies.html',
					controller: 'StaticController',
					meta: {
						title: 'Cookies Policy - Eventvods',
						description: 'Cookie Policy for Eventvods.com',
						event: false
					}
				})
				.when('/promote', {
					templateUrl: '/assets/views/frontend/promote.html',
					controller: 'StaticController',
					meta: {
						title: 'Advertise with us - Eventvods',
						description: 'Advertise wih Eventvods.com',
						event: false
					}
				})
				.when('/about/terms', {
					templateUrl: '/assets/views/frontend/tos.html',
					controller: 'StaticController',
					meta: {
						title: 'Terms of Service - Eventvods',
						description: 'Terms of Service for Eventvods.com',
						event: false
					}
				})
				.when('/about/privacy', {
					templateUrl: '/assets/views/frontend/privacy.html',
					controller: 'StaticController',
					meta: {
						title: 'Privacy Policy - Eventvods - Esports on Demand',
						description: 'Privacy Policy for Eventvods.com',
						event: false
					}
				})
				.when('/about/team', {
					templateUrl: '/assets/views/frontend/our_team.html',
					controller: 'StaticController',
					meta: {
						title: 'Our Team - Eventvods',
						description: 'Meet our team and get to know the people that deliver live updates for your spoiler-free esports experience. Most of our staff are volunteers!',
						event: false
					}
				})
				.when('/about/faq', {
					templateUrl: '/assets/views/frontend/faq.html',
					controller: 'StaticController',
					meta: {
						title: 'FAQ - Frequently Asked Questions - Eventvods',
						description: 'All your questions answered. Why don\'t you cover Runescape? What\'s the purpose of live? We answer them all.',
						event: false
					}
				})
				.when('/account', {
					templateUrl: '/assets/views/frontend/settings.html',
					controller: 'SettingsController',
					controllerAs: 'Settings',
					meta: {
						title: 'Account Settings - Eventvods',
						description: 'Watch all your favourite Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: false
					}
				})
				.when('/reset', {
					templateUrl: '/assets/views/frontend/reset.html',
					controller: 'ResetController',
					controllerAs: 'Reset',
					meta: {
						title: 'Reset Password - Eventvods',
						description: 'Watch all your favourite Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: false
					}
				})
				.when('/venatus', {
					templateUrl: '/assets/views/frontend/venatus.html',
					controller: 'StaticController',
					meta: {
						title: 'Venatus test page - Eventvods',
						description: 'Watch all your favourite Esports vods and highlights on demand,  easily and spoiler-free. Rate, favorite and share matches of your favorite teams!',
						event: false
					}
				})
				.otherwise({
					templateUrl: '/assets/views/frontend/404.html',
					controller: 'StaticController'
				});
			$locationProvider.html5Mode({
				enabled: true,
				requireBase: true
			});

		}]);
}());
